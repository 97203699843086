<template>
  <v-container
      id="projects"
      fluid
      align="center"
  >

    <base-material-card
        icon="mdi-credit-card"
        inline
        title="Edit Card"
    >
      <v-card-text class="pa-0 ma-0">
        <div v-if="canAccess({module: 'saas', method: 'get', route: '/paymentMethod'})" class="float-right">
          <v-btn small color="warning lighten-2" @click="$router.push({name: 'Payment'})">
            <v-icon class="mr-2">mdi-arrow-left-circle</v-icon>
            Back
          </v-btn>
        </div>
        <br/>
        <br/>
        <v-container>
          <v-row>
            <v-col md="6" sm="12" xs="12">
              <br/>
              <h4 class="mb-4 ml-0">Card Information</h4>

              <v-row>
                <v-col cols="5">
                  <v-text-field
                      disabled
                      color="secondary"
                      v-model="myCard.type"
                      dense
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                      :items="['1','2','3','4','5','6','7','8','9','10','11', '12']"
                      label="Month"
                      v-model="myCard.cardMonth"
                      dense>
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                      :items="cardAllowedYears"
                      label="Year"
                      v-model="myCard.cardYear"
                      dense
                  ></v-select>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12">
                  <h4 class="mb-4 ml-0">Billing Information</h4>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          type="email"
                          color="secondary"
                          label="Email"
                          v-model="billingInfo.email"
                          dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          type="phone"
                          color="secondary"
                          label="Phone"
                          v-model="billingInfo.phone"
                          dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          color="secondary"
                          label="Street"
                          v-model="billingInfo.address.line1"
                          dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                          color="secondary"
                          label="City"
                          v-model="billingInfo.address.city"
                          dense
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                          color="secondary"
                          label="State"
                          v-model="billingInfo.address.state"
                          dense
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                          color="secondary"
                          label="Postal Code"
                          v-model="billingInfo.address.postal_code"
                          dense
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <div class="v-input__slot country-select">
                        <div class="v-select__slot">
                          <country-select class="v-label theme--light"
                                          color="secondary" placeholder="Country"
                                          v-model="billingInfo.address.country" :country="billingInfo.address.country"/>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" sm="12" xs="12" class="align-center text-center">
              <br/>
              <v-spacer></v-spacer>
              <vue-paycard :value-fields="myCard"/>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-if="canAccess({module: 'saas', method: 'patch', route: '/paymentMethod/:pmId'})"
            right
            color="secondary"
            @click="checkForm"
        >
          <v-icon class="mr-1">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn
            right
            color="error"
            @click="resetForm"
        >
          <v-icon class="mr-1">mdi-cancel</v-icon>
          Reset
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import {VuePaycard} from 'vue-paycard'

const data = {
  loading: true,
  originalCopy: {},
  cardAllowedYears: [],
  billingInfo: {
    address: {}
  },
  myCard: {
    cardName: '',
    cardNumber: '',
    cardMonth: '',
    cardYear: ''
  }
};

const methods = {

  resetForm() {
    this.myCard = this._lodash.cloneDeep(this.originalCopy.display);
    this.billingInfo = this._lodash.cloneDeep(this.originalCopy.billingInfo);
  },

  checkForm() {
    const self = this;

    if (!this.myCard.cardMonth || this.myCard.cardMonth === '') {
      self.pushMessage({
        type: 'error',
        title: 'Credit Card Error',
        text: 'Please enter the card expiry month!'
      });
      return false;
    }

    if (!this.myCard.cardYear || this.myCard.cardYear === '') {
      self.pushMessage({
        type: 'error',
        title: 'Credit Card Error',
        text: 'Please enter the card expiry year!'
      });
      return false;
    }

    //billing

    if (!this.billingInfo.email || this.billingInfo.email.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter the your email!'
      });
      return false;
    }

    if (!this.billingInfo.phone || this.billingInfo.phone.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter the your phone number!'
      });
      return false;
    }

    if (!this.billingInfo.address.line1 || this.billingInfo.address.line1.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing street address!'
      });
      return false;
    }

    if (!this.billingInfo.address.city || this.billingInfo.address.city.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing city name!'
      });
      return false;
    }

    if (!this.billingInfo.address.state || this.billingInfo.address.state.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing state name!'
      });
      return false;
    }

    if (!this.billingInfo.address.postal_code || this.billingInfo.address.postal_code.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing postal_code!'
      });
      return false;
    }

    if (!this.billingInfo.address.country || this.billingInfo.address.country.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing country!'
      });
      return false;
    }

    // ask for postal code optionally
    this.saveCard();
  },

  saveCard() {
    const self = this;
    let options = {
      url: `/saas/paymentMethod/${this.id}`,
      method: 'patch',
      params: {
        "billing": {
          // "name": self.myCard.cardName,
          "email": self.billingInfo.email,
          "phone": self.billingInfo.phone
        }
      }
    };

    if (!self._lodash.isEqual(self.originalCopy.billingInfo.address, self.billingInfo.address)) {
      options.params.billing.address = self.billingInfo.address;
    }

    if ((self.myCard.cardMonth !== self.originalCopy.display.cardMonth) ||
        (self.myCard.cardYear !== self.originalCopy.display.cardYear)) {
      options.params.billing.card = {
        "exp_month": self.myCard.cardMonth,
        "exp_year": self.myCard.cardYear,
      };
    }

    self.getSendData(options)
        .then(response => {
          self.pushMessage({
            type: 'success',
            title: 'Credit Card',
            text: 'Credit Card Saved Successfully'
          });
          setTimeout(() => {
            self.initialize();
            self.$router.push({name: 'Payment'});
          }, 3000);
        });
  },

  async initialize() {
    const self = this;
    self.getPaymentMethod();
  },

  async getPaymentMethod() {
    const self = this;
    this.loading = true;
    self.getSendData({
      noLoading: true,
      url: '/saas/paymentMethod',
      method: 'get',
      params: {}
    }).then(response => {
      response.rows.forEach((oneCard) => {

        if (oneCard.id === this.id) {

          if (!oneCard.billing_details.name || oneCard.billing_details.name.trim() === '') {
            oneCard.billing_details.name = null;
          }

          if (!oneCard.billing_details.email || oneCard.billing_details.email.trim() === '') {
            oneCard.billing_details.email = null;
          }

          if (!oneCard.billing_details.phone || oneCard.billing_details.phone.trim() === '') {
            oneCard.billing_details.phone = null;
          }

          self.billingInfo = oneCard.billing_details;
          self.billingInfo.address = oneCard.billing_details.address;

          oneCard.display = {
            cardName: oneCard.billing_details.name,
            cardNumber: "xxxx xxxx xxxx " + oneCard.card.last4,
            cardMonth: oneCard.card.exp_month.toString(),
            cardYear: oneCard.card.exp_year,
            type: oneCard.card.brand.toUpperCase()
          };

          this.myCard = oneCard.display;
          self.originalCopy = {
            display: this._lodash.cloneDeep(oneCard.display),
            billingInfo: this._lodash.cloneDeep(self.billingInfo)
          };
        }

      });
      self.loading = false;

    }).catch(() => {
      self.loading = false;
    });

  },

};

export default {
  name: 'editPaymentMethod',
  methods: methods,
  props: {
    id: {
      type: String
    }
  },
  components: {
    VuePaycard
  },

  computed: {},

  mixins: [globalMixins],

  async created() {
    let thisYear = new Date().getFullYear();
    for (let i = thisYear; i <= thisYear + 10; i++) {
      this.cardAllowedYears.push(i);
    }

    this.clearMessages();
    setTimeout(() => {
      this.initialize();
    }, 200);
  },

  data: () => (data),
}
</script>
<style>

select {

}

.country-select .v-select__slot {
  padding: 6px 0 4px;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.3);
}

.country-select select option {
  padding-top: 10px;
  padding-bottom: 5px;
  color: black;
}

</style>